import * as SentrySvelte from '@sentry/svelte'
import LogRocket from 'logrocket'

/**
 * Report to our trackers and analytics if the page is loaded within an iFrame.
 */
export const logIfInIframe = () => {
    const inIframe = inIFrame()

    SentrySvelte.setTag('in_iframe', String(inIframe))

    if (inIframe) {
        LogRocket.track('Page loaded within an iFrame.')
    }
}

/**
 * Check whether we are in an iframe or not.
 *
 * @returns {boolean}
 */
export const inIFrame = () => {
    try {
        return window.self !== window.top
    } catch (e) {
        return true
    }
}
